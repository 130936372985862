<template>
  <div class="console-page">
    <!-- 用户 订单 销售 访问量 -->
    <div class="title-content">
      <div v-for="(item, index) in titleType" :key="item.id" class="title-style bg-white">
        <!-- 标题 -->
        <div class="title-wapper">
          <div class="title">{{ item.title }}</div>
          <div class="time" :class="['time' + index]">{{ item.time }}</div>
        </div>
        <!-- 数据信息 -->
        <div class="amount-wapper">
          <div class="trafficData">
            {{ item.amount | toAmount }}
            <span style="fontsize: 24px" v-if="index == 3">位</span>
          </div>
          <div :class="['amount' + index]" v-if="index == 0">
            日同比
            <span>
              {{ item.info.day }}
              <Icon type="md-arrow-dropup" color="#ed4014" />
            </span>
            周同比
            <span>
              {{ item.info.week }}
              <Icon type="md-arrow-dropdown" color="#19be6b" />
            </span>
          </div>
          <div :class="['amount' + index]" v-if="index == 1">
            <Progress :percent="item.info" hide-info />
          </div>
          <div :class="['amount' + index]" v-if="index == 2">
            <div id="orderSize"></div>
          </div>
          <div :class="['amount' + index]" v-if="index == 3">
            <span v-for="(items, index) in item.info.urls" :key="index">
              <Avatar icon="ios-person" size="small" :src="items" />
            </span>
            <span style="marginleft: -6px">
              <Avatar
                size="small"
                :style="{ background: '#FDE3CF', color: '#F56A00' }"
              >+{{ item.info.lengths }}</Avatar>
            </span>
          </div>
        </div>

        <div class="gross-wrapper">
          <span>{{ item.gross.title }}</span>
          <span>{{ item.gross.num }}</span>
        </div>
      </div>
    </div>
    <!-- 标签页 -->
    <div class="label-content">
      <div class="label-wrapper" v-for="item in labelArr" :key="item.id">
        <Icon :type="item.image" size="32" :color="item.color" />
        <p>{{ item.title }}</p>
      </div>
    </div>
    <!-- 访问量数据 -->
    <div class="visitor-volume-content">
      <div class="header">
        <div class="header-left">
          <div class="header-icon">
            <Icon type="md-podium" color="rgb(24, 144, 255)" />
          </div>
          <span>访问量</span>
        </div>
        <div class="header-right">
          <span @click="timing = 'day'" :class="[timing == 'day' ? 'pitch-on' : '']">今日</span>
          <span @click="timing = 'month'" :class="[timing == 'month' ? 'pitch-on' : '']">本周</span>
          <span
            @click="timing = 'year'"
            :class="[timing == 'year' ? 'pitch-on' : '']"
            style="marginright: 8px"
          >本月</span>
          <DatePicker
            type="daterange"
            :start-date="new Date(1991, 4, 14)"
            placement="bottom-end"
            placeholder="选择起始日期查询"
            style="width: 200px"
          ></DatePicker>
        </div>
      </div>
      <div class="content">
        <div class="content-left">
          <h4>访问量趋势</h4>
          <div id="visitor-volume"></div>
        </div>
        <div class="content-right">
          <h4>最常用量表</h4>
          <ul class="ranking">
            <li class="ranking-content" v-for="(item, index) in ranking" :key="index">
              <div class="order">
                <span
                  class="header-icon"
                  :class="[index > 3 ? 'header-icon-no' : '']"
                >{{ index + 1 }}</span>
                <span>{{ item.title }}</span>
              </div>
              <span>{{ item.num | toAmount }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 热门搜索 用户画像 -->
    <div class="search-portrayal-content">
      <div class="content-left">
        <div class="header">
          <div class="header-left">
            <div
              class="header-icon"
              style="
                color: rgb(114, 46, 209);
                backgroundcolor: rgb(249, 240, 255);
              "
            >
              <Icon type="ios-search" />
            </div>
            <span>访问量</span>
          </div>
          <div class="header-right">
            <Dropdown>
              <Icon type="md-more" />
              <DropdownMenu slot="list">
                <DropdownItem>操作1</DropdownItem>
                <DropdownItem>操作2</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div class="chart">
          <div class="chart-content">
            <p>
              搜索用户数
              <Tooltip placement="top" content="指标说明">
                <Icon type="ios-information-circle-outline" />
              </Tooltip>
            </p>
            <p class="chart-num">
              <span class="chart-num1">23,378</span>
              <span class="chart-num2">16.8</span>
              <Icon type="md-arrow-dropup" color="#ed4014" />
            </p>
            <div id="searchData1"></div>
          </div>
          <div class="chart-content">
            <p>
              人均搜索次数
              <Tooltip placement="top" content="指标说明">
                <Icon type="ios-information-circle-outline" />
              </Tooltip>
            </p>
            <p class="chart-num">
              <span class="chart-num1">3.1</span>
              <span class="chart-num2">8.5</span>
              <Icon type="md-arrow-dropdown" color="#19be6b" />
            </p>
            <div id="searchData2"></div>
          </div>
        </div>
        <div class="form">
          <Table :columns="columns" :data="data">
            <template slot-scope="{ row, index }" slot="address">
              {{ row.address }}%
              <Icon v-if="row.ratio == 'rise'" type="md-arrow-dropup" color="#ed4014" />
              <Icon v-else type="md-arrow-dropdown" color="#19be6b" />
            </template>
          </Table>
          <div class="page-style">
            <Page :total="100" size="small" @on-change="changePage" />
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="header">
          <div class="header-left">
            <div
              class="header-icon"
              style="
                color: rgb(250, 173, 20);
                backgroundcolor: rgb(255, 251, 230);
              "
            >
              <Icon type="ios-people" />
            </div>
            <span>用户画像</span>
          </div>
          <div class="header-right">
            <Tooltip placement="top" content="下载数据">
              <Icon type="ios-download-outline" />
            </Tooltip>
          </div>
        </div>
        <div class="content">
          <ul class="header-label">
            <li>
              <div class="header-icon male-style">
                <Icon type="ios-man" color="rgb(51, 153, 255)" />
              </div>
              <p>男性 65%</p>
            </li>
            <li>
              <div class="header-icon womon-style"></div>
              <p>女性 25%</p>
            </li>
            <li>
              <div class="header-icon unknown"></div>
              <p>未知 25%</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "@antv/g2";

export default {
  data() {
    return {
      titleType: [
        {
          id: 0,
          title: "访问量",
          amount: 125848,
          time: "日",
          gross: {
            title: "总访问量",
            num: "280万",
          },
          info: {
            day: "12.5%",
            week: "5%",
          },
        },
        {
          id: 1,
          title: "销售额",
          amount: 12000,
          time: "月",
          gross: {
            title: "总销售额",
            num: "68万",
          },
          info: 35,
        },
        {
          id: 2,
          title: "订单量",
          amount: 1680,
          time: "周",
          gross: {
            title: "转化率",
            num: "60%",
          },
          info: [
            { year: "1991", value: 15468 },
            { year: "1992", value: 16100 },
            { year: "1993", value: 15900 },
            { year: "1994", value: 17409 },
            { year: "1995", value: 17000 },
            { year: "1996", value: 31056 },
            { year: "1997", value: 31982 },
            { year: "1998", value: 32040 },
            { year: "1999", value: 33233 },
          ],
        },
        {
          id: 3,
          title: "新增用户",
          amount: 25848,
          tiem: null,
          gross: {
            title: "总用户",
            num: "10880人",
          },
          info: {
            urls: [
              "https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar",
              "https://dev-file.iviewui.com/zhj85zgAfEjChCNIKT1LQENUIOyOYCaX/avatar",
              "https://dev-file.iviewui.com/TkH54UozsINlex15TAMI00GElsfsKSiC/avatar",
              "https://dev-file.iviewui.com/xrzbBR99F6tYsDJPLNrvwhllowbuL7Gw/avatar",
              "https://dev-file.iviewui.com/bgrngoUb9A6UQ2kAwBFtnSNzhrh2qj1O/avatar",
              "https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar",
            ],
            lengths: 3,
          },
        },
      ],
      labelArr: [
        {
          id: 0,
          title: "用户",
          image: "md-people",
          color: "rgb(105, 192, 255)",
        },
        {
          id: 1,
          title: "分析",
          image: "md-podium",
          color: "rgb(149, 222, 100)",
        },
        { id: 2, title: "量表", image: "md-cart", color: "rgb(255, 156, 110)" },
        {
          id: 3,
          title: "订单",
          image: "md-clipboard",
          color: "rgb(179, 127, 235)",
        },
        { id: 4, title: "票据", image: "md-card", color: "rgb(255, 214, 102)" },
        { id: 5, title: "消息", image: "md-mail", color: "rgb(92, 219, 211)" },
        {
          id: 6,
          title: "标签",
          image: "md-pricetags",
          color: "rgb(255, 133, 192)",
        },
        {
          id: 7,
          title: "配置",
          image: "md-switch",
          color: "rgb(255, 192, 105)",
        },
      ],
      ranking: [
        { title: "90项症状清单", num: 521 },
        { title: "Achenbach儿童行为量表（CBCL-2）", num: 421 },
        { title: "抑郁自评量表（SDS）", num: 412 },
        { title: "焦虑自评量表（SAS）", num: 367 },
        { title: "中国中学生心理健康量表", num: 322 },
      ],
      hotSearch: [],
      timing: "day",
      columns: [
        {
          title: "排名",
          type: "index",
          width: "100px",
        },
        {
          title: "搜索关键词",
          key: "search",
        },
        {
          title: "用户数",
          key: "users",
          sortable: true,
        },
        {
          title: "周涨幅",
          align: "right",
          sortable: true,
          slot: "address",
        },
      ],
      data: [
        {
          search: "John Brown",
          users: 18,
          address: 80,
          ratio: "decline",
        },
        {
          search: "Jim Green",
          users: 24,
          address: 32,
          ratio: "rise",
        },
        {
          search: "Joe Black",
          users: 30,
          address: 15,
          ratio: "rise",
        },
        {
          search: "Jon Snow",
          users: 26,
          address: 20,
          ratio: "decline",
        },
      ],
    };
  },
  filters: {
    toAmount(val) {
      return val.toLocaleString();
    },
  },
  mounted() {
    this.initOrderQuantity();
    this.initVolumeContent();
    this.initSearchData2();
    this.initSearchData1();
  },
  methods: {
    //   初始化视图数据
    initOrderQuantity() {
      const data = [
        { year: "1991", value: 10 },
        { year: "1992", value: 300 },
        { year: "1993", value: 150 },
        { year: "1994", value: 450 },
        { year: "1995", value: 70 },
        { year: "1996", value: 210 },
        { year: "1997", value: 130 },
      ];
      const chart = new Chart({
        container: "orderSize",
        autoFit: true,
        height: 30,
      });

      chart.data(data);
      chart.scale({
        value: {
          min: 500,
          nice: true,
        },
        year: {
          range: [0, 1],
        },
      });
      chart.area().position("year*value");
      chart.line().position("year*value");
      chart.axis(false); // 不展示坐标轴
      chart.render();
    },

    // 初始化访问量数据视图
    initVolumeContent() {
      const data = [
        { month: "1月", sales: 9728 },
        { month: "2月", sales: 4578 },
        { month: "3月", sales: 12768 },
        { month: "4月", sales: 4928 },
        { month: "5月", sales: 9665 },
        { month: "6月", sales: 5486 },
        { month: "7月", sales: 2358 },
        { month: "8月", sales: 13547 },
        { month: "9月", sales: 6587 },
        { month: "10月", sales: 5487 },
        { month: "11月", sales: 7548 },
        { month: "12月", sales: 5658 },
      ];
      const chart = new Chart({
        container: "visitor-volume",
        autoFit: true,
        height: 240,
      });

      chart.data(data);
      chart.scale("sales", {
        alias: "访问量",
        nice: true,
      });
      chart.tooltip({
        showCrosshairs: true,
      });
      // let barWidth = 65 * (window.innerWidth / 1960);
      chart
        .interval()
        .position("month*sales")
        .color("l(90) 0:#68cdff 1:#1495eb")
        .style({ radius: [3, 3, 0, 0] });
      chart.render();
    },

    // 初始化搜索用户数视图
    initSearchData1() {
      const data = [
        { year: "1991", value: 10 },
        { year: "1992", value: 300 },
        { year: "1993", value: 150 },
        { year: "1994", value: 350 },
        { year: "1995", value: 70 },
        { year: "1996", value: 210 },
        { year: "1997", value: 130 },
      ];
      const chart = new Chart({
        container: "searchData1",
        autoFit: true,
        height: 35,
      });

      chart.data(data);
      chart.scale({
        value: {
          min: 500,
          nice: true,
        },
        year: {
          range: [0, 1],
        },
      });
      chart.area().position("year*value");
      chart.line().position("year*value").shape("smooth");
      chart.axis(false); // 不展示坐标轴
      chart.render();
    },
    // 初始化人均搜索次数视图
    initSearchData2() {
      const data = [
        { year: "1991", value: 10 },
        { year: "1992", value: 300 },
        { year: "1993", value: 150 },
        { year: "1994", value: 350 },
        { year: "1995", value: 70 },
        { year: "1996", value: 210 },
        { year: "1997", value: 130 },
      ];

      const chart = new Chart({
        container: "searchData2",
        autoFit: true,
        height: 35,
      });

      chart.data(data);
      chart.scale({
        value: {
          min: 500,
          nice: true,
        },
        year: {
          range: [0, 1],
        },
      });
      chart.line().position("year*value").shape("smooth");
      chart.area().position("year*value");
      chart.axis(false); // 不展示坐标轴
      chart.render();
    },

    //
    sortMethod(a, b) {
      console.log(a, b);
    },
    // 改变页数
    changePage(val) {
      console.log(val);
    },
  },
};
</script>
<style  lang="less" scoped>
.console-page {
  width: 98%;
  margin: 0 auto;
  color: #515a6e;
  .title-content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title-style {
      box-sizing: border-box;
      width: 24%;
      border-radius: 4px;
      color: #515a6e;
      .title-wapper {
        display: flex;
        justify-content: space-between;
        padding: 14px 16px;
        border-bottom: 1px solid #e8eaec;
        .title {
          font-size: 16px;
          color: #17233d;
        }
        .time {
          line-height: 22px;
          height: 24px;
          padding: 0 8px;
          border-radius: 3px;
          font-size: 12px;
        }
        .time0 {
          background-color: #f6ffed;
          border: 1px solid #b7eb8f;
          color: #52c41a !important ;
        }
        .time1 {
          background-color: #e6f7ff;
          border: 1px solid #91d5ff;
          color: #1890ff !important;
        }
        .time2 {
          background-color: #fff1f0;
          border: 1px solid #ffa39e;
          color: #f5222d !important;
        }
      }
      .amount-wapper {
        padding: 12px;
        .trafficData {
          font-size: 30px;
          text-align: left;
          padding-bottom: 8px;
        }

        .amount0 {
          height: 30px;
          text-align: left;
          font-size: 14px;
          span {
            font-size: 14px;
            margin-right: 16px;
          }
        }
        .amount1 {
          height: 30px;
        }
        .amount2 {
          height: 30px;
        }
        .amount3 {
          height: 30px;
          margin-left: 6px;
          text-align: left;
          .ivu-avatar-small.ivu-avatar-icon {
            margin-left: -6px;
            border: 1px solid white;
          }
        }
      }
      .gross-wrapper {
        width: 94%;
        margin: 0 auto;
        padding: 12px 0;
        display: flex;
        border-top: 1px solid #e8eaec;
        justify-content: space-between;
      }
    }
  }
  .label-content {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .label-wrapper {
      box-sizing: content-box;
      border-radius: 4px;
      width: 10%;
      padding: 16px 11px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      p {
        margin-top: 8px;
      }
    }
    .label-wrapper:hover {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
      border-color: #eee;
    }
  }
  .visitor-volume-content {
    margin-top: 20px;
    width: 100%;
    background-color: #fff;

    .content {
      display: flex;
      padding: 14px 16px;
      justify-content: space-between;
      .content-left {
        width: 65%;
        h4 {
          text-align: left;
        }
        #visitor-volume {
          padding: 20px 12px 0;
        }
      }
      .content-right {
        width: 32%;
        h4 {
          text-align: left;
        }
        .ranking {
          .ranking-content {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            .order {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .search-portrayal-content {
    margin: 20px 0 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .content-left {
      width: 49%;
      border-radius: 4px;
      background-color: #fff;
      .chart {
        padding: 14px 16px;
        display: flex;
        justify-content: space-between;
        .chart-content {
          width: 48%;
          p {
            color: #808695;
            text-align: left;
          }
          .chart-num {
            margin-top: 8px;
            .chart-num1 {
              margin-right: 32px;
              color: #515a6e;
              font-size: 24px;
            }
          }
        }
      }
      .form {
        width: 96%;
        margin: 0 auto;
        .ivu-table-header thead tr th {
          background-color: #fff !important;
        }
        .page-style {
          padding: 14px 16px;
          text-align: right;
        }
      }
    }
    .content-right {
      width: 49%;
      border-radius: 4px;
      background-color: white;
      .content {
        padding: 16px;
        .header-label {
          display: flex;
          justify-content: space-around;
          li {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            .male-style {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}

.header-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(230, 247, 255);
  margin-right: 8px;
  color: rgb(24, 144, 255);
}
.header-icon-no {
  color: rgb(140, 140, 140);
  background: #f5f5f5;
}
.header {
  padding: 14px 16px;
  display: flex;
  border-bottom: 1px solid #e8eaec;
  justify-content: space-between;
  .header-left {
    display: flex;
    align-items: center;
  }
  .header-right {
    display: flex;
    justify-content: center;
    align-items: center;
    .pitch-on {
      color: #2d8cf0;
    }
    span {
      cursor: pointer;
      padding: 0 12px;
    }
  }
}
#searchData1,
#searchData2 {
  margin-top: 10px;
}
</style>
<style >
.form .ivu-table-header thead tr th {
  background-color: #fff !important;
}
</style>